import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import PageFascia from "../components/page-fascia"
import withPreview from "../utility/with-preview"


const IndexPage = ({ data: { meta, page: { pageTitle, htmlTitle, hero, fasce, tags }, menuCorporate, tileList }, 
  pageContext: { langCode }, location }) => {
  const canonical = (htmlTitle.content && htmlTitle.content.canonicalUrl && htmlTitle.content.canonicalUrl.value) 
    || `${meta.siteUrl}/${langCode === 'en-US' ? 'en/' : ''}`;
  return (
    <Layout langCode={langCode} location={location} tags={tags} >
      <Seo title={htmlTitle.value || pageTitle.value} langCode={langCode} ogImage={htmlTitle.content.ogImage}
        description={htmlTitle.content && htmlTitle.content.metaDescription && htmlTitle.content.metaDescription.value}
        skipEn={pageTitle.content && pageTitle.content.skipEn} canonical={canonical}
        robots={htmlTitle.content && htmlTitle.content.metaRobots && htmlTitle.content.metaRobots.value} />
      <Hero data={hero} menu={menuCorporate} />
      {fasce.map((fascia, i) => <PageFascia key={i} data={fascia} tileList={tileList} />)}
    </Layout>
  );
}

export default withPreview(IndexPage, { subField: 'page', fixed: true });

export const query = graphql`query IndexQuery($langCode: String!, $homeArticleId: String!){
  meta: gatsbySourceLiferayOptions {
    siteUrl
  }
  page: liferayPaginainterna(articleId: { eq: $homeArticleId }, langCode: {eq: $langCode}) {
    siteId
    articleId
    langCode
    pageTitle {
      value
      content {
        skipEn
        friendlyUrl {
          value
        }
      }
    }
    htmlTitle {
      value
      content {
        metaDescription {
          value
        }
        canonicalUrl {
          value
        }
      }
      ...PaginainternaTitleFragment
    }
    hero {
      __typename
      ...SiteHeader_HeroFragment
    }
    ...FasceFragment
    tags: taxonomyCategoryBriefs {
      taxonomyCategoryId
      taxonomyCategoryName
    }
  }
  menuCorporate: liferayMenuCorporate(langCode: {eq: $langCode}){
    pageTitle {
      value
      content {
        icon {
          value
        }
        description {
          value
        }
        link {
          value
        }
        ctaText {
          value
          content {
            ctaLink {
              value
            }
          }
        }
      }
    }
  }
  #tileList: allLiferayTile(filter: {langCode: {eq: $langCode}, cardTitle: {content: {skipEn: { ne: true}}}}, 
  tileList: allLiferayTile(filter: {langCode: {eq: $langCode}}, sort: {fields: effectiveDate, order: DESC}) {
    edges {
      node {
        ... TileFragment
      }
    }
  }
}`
